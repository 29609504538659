.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    border-radius: 6px;
    width: 546px;
    height: 211px;
    max-width: 90%;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 20px;
    margin-bottom: 24px;
}

.description {
    font-size: 14px;
    font-weight: 390;
    color: #696969;
    margin-bottom: 20px;
    line-height: 15px;
}

.actions {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    gap: 18px;
    justify-content: flex-end;
}

.secondaryButton {
    background-color: #ffffff;
    font-size: 10px;
    font-weight: 600;
    color: #2f8fff;
    border: 0.5px solid #2f8fff;
    width: 93px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.secondaryButton:hover {
    background-color: #efefef;
}

.primaryButton {
    background-color: #2f8fff;
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    border: 0.5px solid #2f8fff;
    width: 120px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.primaryButton:hover {
    background-color: #0056b3;
}
